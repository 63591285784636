body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Nunito",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* 
  font-family: 'Montserrat', sans-serif;
  font-family: 'Nunito', sans-serif;
  font-family: 'Limelight', cursive;
  font-family: 'Yellowtail', cursive;
  font-family: 'Mirza', cursive;
  font-family: 'Open Sans', sans-serif; */

  background-image: url(./images/homebkg.jpg);
  background-size: auto 100vh;

  --dark-green: #1b9c5e;
  --dark-pink: #b1718d;

  --light-green: #c1ffe1;
  --light-pink: #ffd9f9;

  --pink: #ff98c5;
  --green: #5bc980;
}

.loading-screen {
  width: 100%;
  height: 300px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-text {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Nunito",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  color: #c1ffe1;
  font-size: 36px;
  padding-bottom: 10px;
}

.loading-screen svg {
  height: 36px;
  width: 36px;
  fill: #c1ffe1;
  animation: spinner-spin infinite 1.5s ease;
}

@keyframes spinner-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
